/** Bootstrap JS */
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

/** PopperJS */
import '@popperjs/core';

/** Botstrap SCSS **/
import '../scss/style.scss';

/** FontAwesome 6 */
import '@fortawesome/fontawesome-free/js/all.js';

/** Footer */
document.body.onload = footer;

function footer() {
    // create a new div element
    const footerDiv = document.createElement("footer");

    // assign it a class
    footerDiv.classList.add("footer");
    footerDiv.classList.add("mt-auto");
    footerDiv.classList.add("text-center");
    footerDiv.classList.add("py-3");


    // gets the current date
    const copyright = new Date().getFullYear();

    // gets the copyright symbol
    const favicon = document.createElement("i");
    favicon.classList.add("fas", "fa-copyright");

    const text = document.createTextNode(" 2020 " + "- " + copyright);

    // add the text node to the newly created div
    footerDiv.appendChild(favicon);
    footerDiv.appendChild(text);

    // add the newly created element and its content into the DOM
    const newDiv = document.getElementById("div");
    document.body.insertBefore(footerDiv, newDiv);
}

/** toasts.html */
const toastTrigger = document.getElementById('liveToastBtn');
const toastLiveExample = document.getElementById('liveToast');

if (toastTrigger) {
    const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample);
    toastTrigger.addEventListener('click', () => {
        toastBootstrap.show();
    });
}

/** off-canvas.html */
const navbarSideCollapse = document.querySelector('#navbarSideCollapse');
const offcanvasCollapse = document.querySelector('.offcanvas-collapse');

if (navbarSideCollapse) {
    navbarSideCollapse.addEventListener('click', () => {
        offcanvasCollapse.classList.toggle('open');
    });
}

/** alerts.html */
const alertPlaceholder = document.getElementById('liveAlertPlaceholder');
const appendAlert = (message, type) => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = [
        `<div class="alert alert-${type} alert-dismissible" role="alert">`,
        `<div>${message}</div>`,
        `<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>`,
        `</div>`
    ].join('');

    alertPlaceholder.append(wrapper);
};

const alertTrigger = document.getElementById('liveAlertBtn');
if (alertTrigger) {
    alertTrigger.addEventListener('click', () => {
        appendAlert('Nice you triggered this alert message!', 'success');
    });
}
